import "leaflet/dist/leaflet.css";

import { useMemo, ReactNode, useEffect, useState } from "react";

import {
  getCachedRasterTileLayer,
  RasterMapID,
} from "components/LeafletMap/CachedRasterTileLayer";
import { ChartMarker } from "components/LeafletMap/ChartMarker";
import { ContextLifter } from "components/LeafletMap/ContextLifter";
import {
  SearchGeocoderControl,
  MouseCoordinateControl,
  CustomZoomControl,
} from "components/LeafletMap/MapControls";
import { GeolocationControl } from "components/LeafletMap/MapControls/GeolocationControl";
import { MergedMapPublicLegendHelpControl } from "components/LeafletMap/MapControls/MergedMapPublicLegendHelpControl/MergedMapPublicLegendHelpControl";
import { ScaleControlAutoUnit } from "components/LeafletMap/MapControls/ScaleControlAutoUnit/ScaleControlAutoUnit";
import {
  getTangramLayer,
  isTangramLayer,
} from "components/LeafletMap/TangramTileLayer";
import { UrlParamsHandler } from "components/LeafletMap/UrlParamsHandler";
import { WaypointAddingIndicatorWrapper } from "components/LeafletMap/WaypointAddingIndicator/WaypointAddingIndicator";
import { DEFAULT_MAP_ID, MAP_SETTINGS } from "config/constants";
import { useGenericModals } from "hooks/useGenericModals";
import { useMapContext } from "hooks/useMapContext";
import { useMapSettingsSaver } from "hooks/useMapSettingsSaver";
import { useTranslation } from "react-i18next";
import { MapContainer, Pane, useMapEvents } from "react-leaflet";
import { useGenericState } from "stores/genericStore/GenericContext";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { useRouting } from "stores/routingStore/RoutingContext";
import { getTrackByUUID } from "utils/api/getTrackByUUID";
import {
  getQueryLatLng,
  getQueryTrackID,
  getQueryZoom,
} from "utils/getQueryParam";
import { parseTrackFromAPI } from "utils/helpers/trackParser/trackParser";

import { CesiumMap } from "./CesiumMap";
import { MeasurementWrapper } from "./MeasurementContainer";
import { RoutingContainer } from "./RoutingContainer";
import { UserTracksContainer } from "./UserTracksContainer";
import { UserWaypointsContainer } from "./UserWaypointsContainer";
import { premiumMapIds } from "config/enums/mapIDs";

import "./MapViewContainer.css";
import { LoadingOverlay } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const GlobalMapEventsInjector = () => {
  useMapSettingsSaver();
  useMapEvents({ contextmenu: () => false });
  return null;
};

export const MapViewContainer = () => {
  const [activeMapLayer, setActiveMapLayer] = useState<ReactNode | null>(null);
  const [trackID, setTrackID] = useState(getQueryTrackID());
  const { openErrorModal } = useGenericModals();
  const {
    state: { allowPremiumFeatures, mapType: mapLayerID, mapColor },
    loading,
  } = useOptions();
  const { setEditedTrack } = useRouting();
  const { map } = useMapContext();
  const { t } = useTranslation();
  const { showCesiumMap } = useGenericState();
  const isSmallViewport = useMediaQuery("(max-width: 768px)");

  // We need to check for a possible premium map here
  const selectedMapId = useMemo(() => {
    if (mapLayerID === undefined) return;

    return premiumMapIds.has(mapLayerID)
      ? allowPremiumFeatures
        ? mapLayerID
        : DEFAULT_MAP_ID
      : mapLayerID;
  }, [allowPremiumFeatures, mapLayerID]);

  useEffect(() => {
    if (selectedMapId === undefined) return;

    // TODO: We should have a type guard here because now we need to type cast mapLayerID later
    const isTangram = isTangramLayer(selectedMapId);
    if (isTangram) {
      setActiveMapLayer(getTangramLayer(selectedMapId));
    } else {
      setActiveMapLayer(getCachedRasterTileLayer(selectedMapId as RasterMapID));
    }
  }, [selectedMapId, mapColor]);

  useEffect(() => {
    if (!map) return;
    const callApi = async () => {
      if (!trackID) return;
      try {
        const rawTrack = await getTrackByUUID(trackID);
        const track = parseTrackFromAPI(rawTrack);
        setEditedTrack(track, map, true);
        setTrackID(null);
      } catch (error) {
        openErrorModal({
          title: t("generic.error_title"),
          text: t("errors.cannot_open_track_by_id.text"),
        });
      }
    };
    callApi();
  }, [map, openErrorModal, setEditedTrack, t, trackID]);

  const latLng = getQueryLatLng();
  const zoom = getQueryZoom();
  const mapCenterProp = latLng ? { center: latLng } : null;
  const zoomProp = zoom ? { zoom } : null;

  const MemoizedMap = useMemo(
    () =>
      showCesiumMap ? (
        <CesiumMap />
      ) : (
        <MapContainer {...MAP_SETTINGS} {...mapCenterProp} {...zoomProp}>
          {activeMapLayer}
          <Pane
            name="hotlinePane"
            style={{ zIndex: 410, pointerEvents: "none" }}
          />
          <ContextLifter />
          <GlobalMapEventsInjector />
          <UserWaypointsContainer />
          <UserTracksContainer />
          <UrlParamsHandler />
          <RoutingContainer />
          <MeasurementWrapper />
          {!isSmallViewport ? <MouseCoordinateControl position="bottomright" /> : null}
          <WaypointAddingIndicatorWrapper />

          <MergedMapPublicLegendHelpControl position="topright" />
          <SearchGeocoderControl position="topright" />
          <GeolocationControl position="topright" />
          <CustomZoomControl position="topright" />
          <ScaleControlAutoUnit position="bottomleft" />
          <ChartMarker />
        </MapContainer>
      ),
    [
      activeMapLayer,
      isSmallViewport,
      isTangramLayer,
      showCesiumMap,
      selectedMapId,
    ]
  );

  return (
    <>
      <LoadingOverlay
        zIndex={100}
        loaderProps={{ size: 30 }}
        visible={loading}
        overlayBlur={2}
      />
      {MemoizedMap}
    </>
  );
};
