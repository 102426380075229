import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import axios, { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { SavingStatus, Waypoint, WaypointRaw } from "types/app";
import { getUrlsIfAreValidImages } from "utils/api/checkIfUrlIsImage";
import {
  parseWaypointForAPI,
  parseWaypointFromAPI,
} from "utils/helpers/waypointParser/waypointParser";
import { fitCoordsToScreen, fitWaypointToScreen } from "utils/map/fitToScreen";
import { showWaypointDeletionSuccessNotification } from "utils/notifications/waypointNotifications";

import { useAuth } from "./useAuth";
import { useEditMode } from "./useEditMode";
import { useGenericModals } from "./useGenericModals";
import { useMapContext } from "./useMapContext";
import { useUserTracks } from "./useUserTracks";
import { useImageUpload } from "./useImageUpload";
import { updateNonSaveableOptions } from "stores/optionsStore/actions";
import { captureMessage } from "@sentry/react";
import { showErrorNotification } from "utils/notifications/customNotifications";

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;

type SelectWaypointOptions = {
  focus?: boolean;
};

type CreateWaypointOptions = {
  focus?: boolean;
};

export type UpdateWaypointData = Partial<Omit<Waypoint, "id">>;

export interface CreateWaypointData extends Omit<UpdateWaypointData, "id"> {}

interface UserWaypoints {
  waypoints: Waypoint[] | null;
  hiddenWaypointIds: number[];
  fetchWaypoints: () => Promise<Record<string, unknown>>;
  selectedWaypoint: Waypoint | null;
  selectWaypoint: (
    id: number | null,
    options?: SelectWaypointOptions
  ) => Waypoint | null;
  updateWaypoint: (
    id: Waypoint["id"],
    updatedData: UpdateWaypointData
  ) => Promise<unknown>;
  deleteWaypoints: (id: number | number[]) => Promise<unknown>;
  createWaypoint: (
    data: CreateWaypointData,
    options?: CreateWaypointOptions
  ) => Promise<unknown>;
  toggleWaypointVisibility: (waypointId: number) => void;
  isAddingWaypoint: boolean;
  setIsAddingWaypoint: (value: React.SetStateAction<boolean>) => void;
  isFetching: boolean;
  waypointStatus: SavingStatus;
  getImagesFromLinks: () => Promise<string[]>;
  getSelectedWaypointImages: () => Promise<string[]>;
}

const UserWaypointsContext = React.createContext<UserWaypoints>({
  waypoints: null,
  hiddenWaypointIds: [],
  fetchWaypoints: async () => ({}),
  selectedWaypoint: null,
  selectWaypoint: () => null,
  updateWaypoint: async () => null,
  deleteWaypoints: async () => null,
  createWaypoint: async () => null,
  isAddingWaypoint: false,
  setIsAddingWaypoint: () => null,
  isFetching: false,
  waypointStatus: "unsaved",
  getImagesFromLinks: async () => [],
  getSelectedWaypointImages: async () => [],
  toggleWaypointVisibility: () => undefined,
});

export const useProvideUserWaypoints = (): UserWaypoints => {
  const { map } = useMapContext();
  const [waypoints, setWaypoints] = useState<Waypoint[] | null>(null);
  const [hiddenWaypointIds, setHiddenWaypointIds] = useState<number[]>([]);
  const [selectedWaypointId, setSelectedWaypointId] = useState<number | null>(
    null
  );
  const [isAddingWaypoint, setIsAddingWaypoint] = useState(false);
  const [waypointStatus, setWaypointStatus] = useState<SavingStatus>("unsaved");
  const [isFetching, setIsFetching] = useState(false);
  const { selectedTrack } = useUserTracks();
  const { setEditMode, editMode } = useEditMode();
  const { openConfirmLosingTrackModal, openErrorModal } = useGenericModals();
  const { t } = useTranslation();
  const {
    dispatch: dispatchOptions,
    state: { language },
  } = useOptions();
  const { getStorageSize } = useImageUpload();
  const { user } = useAuth();
  const waypointsRef = useRef(waypoints);
  const selectedWaypointIdRef = useRef(selectedWaypointId);
  const editModeRef = useRef(editMode);
  const isTrackSavedRef = useRef(!!selectedTrack);

  useEffect(() => {
    waypointsRef.current = waypoints;
  }, [waypoints]);

  useEffect(() => {
    selectedWaypointIdRef.current = selectedWaypointId;
  }, [selectedWaypointId]);

  useEffect(() => {
    editModeRef.current = editMode;
  }, [editMode]);

  useEffect(() => {
    isTrackSavedRef.current = !!selectedTrack;
  }, [selectedTrack]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") setIsAddingWaypoint(false);
  };

  useEffect(() => {
    if (isAddingWaypoint) window.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [isAddingWaypoint]);

  const reset = () => {
    setWaypointStatus("unsaved");
    setWaypoints(null);
    setHiddenWaypointIds([]);
    setSelectedWaypointId(null);
    setIsAddingWaypoint(false);
    setIsFetching(false);
  };

  useEffect(() => {
    if (!user) {
      reset();
    }
  }, [user]);

  const fetchWaypoints = useCallback(async () => {
    if (!user) return Promise.reject(new Error("not_signed_in"));
    try {
      const { data } = await axios.post(
        `${BASE_API_URL}/get-waypoints`,
        {
          agent: "Trackbook",
          lng: language,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const rawWaypoints: WaypointRaw[] = data.waypoints;
      const parsedWaypoints = rawWaypoints.map((waypoint) =>
        parseWaypointFromAPI(waypoint)
      );

      setWaypoints(parsedWaypoints);
      setHiddenWaypointIds(
        parsedWaypoints
          ?.filter((waypoint) => waypoint.visible === false)
          .map((waypoint) => waypoint.id) ?? []
      );
      return data.waypoints;
    } catch (error) {
      setWaypoints(null);
      setHiddenWaypointIds([]);
      return console.error("error getting waypoints");
    }
  }, [language, user]);

  const updateWaypoint = useCallback(
    async (id: number, newData: UpdateWaypointData) => {
      const originalWaypointData = id
        ? waypointsRef.current?.find((value) => value.id === id)
        : {};
      const updatedWaypointData = { ...originalWaypointData, ...newData };
      const parsedData = parseWaypointForAPI(updatedWaypointData);

      if (!user) throw new Error("not_signed_in");

      setWaypointStatus("saving");

      try {
        await axios.post(
          `${BASE_API_URL}/update-waypoint`,
          {
            ...parsedData,
            agent: "Trackbook",
            lng: language,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        fetchWaypoints();
        setWaypointStatus("saved");
        return null;
      } catch (error) {
        setWaypointStatus("unsaved");
        openErrorModal({
          title: t("generic.error_title"),
          text: t("errors.updating_waypoint_failed.text"),
        });
        throw error;
      }
    },
    [fetchWaypoints, language, openErrorModal, t, user]
  );

  const createWaypoint = useCallback(
    async (data: CreateWaypointData, options?: CreateWaypointOptions) => {
      await updateWaypoint(-1, {
        name: "Waypoint",
        src: "Trackbook",
        desc: "",
        icon: "",
        links: [],
        ...data,
      });
      if (options?.focus === true && data.point && map)
        fitCoordsToScreen(data.point, map, 14);
    },
    [map, updateWaypoint]
  );

  const deleteWaypoints = useCallback(
    async (id: number | number[]) => {
      try {
        let singleDeletedWaypointName;
        if (typeof id === "number" || id.length === 1) {
          const currentId = typeof id === "number" ? id : id[0];
          const waypointToBeDeleted = waypointsRef.current?.find(
            (waypoint) => waypoint.id === currentId
          );
          singleDeletedWaypointName = waypointToBeDeleted?.name;
        }

        await axios.post(
          `${BASE_API_URL}/delete-waypoint`,
          {
            id: typeof id === "number" ? id : undefined,
            ids: Array.isArray(id) ? id : undefined,
            agent: "Trackbook",
            lng: language,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        if (singleDeletedWaypointName) {
          showWaypointDeletionSuccessNotification({
            name: singleDeletedWaypointName,
          });
        } else if (Array.isArray(id)) {
          showWaypointDeletionSuccessNotification({ count: id.length });
        }

        try {
          const size = await getStorageSize();

          dispatchOptions(
            updateNonSaveableOptions({
              storageSize: size,
            })
          );
        } catch (error) {
          captureMessage(
            `Trackbook: Errors when getting storage size: ${
              (error as AxiosError | Error)?.message
            }`
          );
        }

        fetchWaypoints();
      } catch (error) {
        openErrorModal({
          title: t("generic.error_title"),
          text: t("errors.error_deleting_waypoint.text"),
        });
      }
    },
    [fetchWaypoints, language, openErrorModal, t]
  );

  const selectWaypoint = useCallback(
    (id: number | null, options?: SelectWaypointOptions) => {
      if (id === null) {
        setSelectedWaypointId(null);
        return null;
      }
      if (!waypointsRef.current) throw new Error("Waypoints empty");
      const waypoint = waypointsRef.current?.find((point) => point.id === id);
      if (!waypoint) throw new Error("Waypoint not found");

      const select = () => {
        setWaypointStatus("saved");
        setSelectedWaypointId(id);
        setEditMode("waypoint");
        if (map && options?.focus) fitWaypointToScreen(waypoint.point, map);
      };

      if (editModeRef.current === "track" && !isTrackSavedRef.current) {
        openConfirmLosingTrackModal({ onConfirm: select });
        return null;
      }
      select();

      return waypoint;
    },
    [map, openConfirmLosingTrackModal, setEditMode]
  );

  useEffect(() => {
    if (!user) return;
    const fetch = async () => {
      setIsFetching(true);
      await fetchWaypoints();
      setIsFetching(false);
    };
    fetch();
  }, [fetchWaypoints, user]);

  const selectedWaypoint = useMemo(() => {
    const foundWaypoint = waypoints?.find(
      (waypoint) => waypoint.id === selectedWaypointId
    );
    // return a new copy to prevent accident original array mutation
    return foundWaypoint ? { ...foundWaypoint } : null;
  }, [selectedWaypointId, waypoints]);

  /**
   * Returns a list of images that are present in links in all waypoints
   */
  const getImagesFromLinks = async () => {
    const allLinks = waypoints?.reduce<string[]>(
      (links, waypoint) => [...links, ...waypoint.links],
      []
    );
    if (!allLinks || allLinks.length === 0) return [];
    const imageUrls = await getUrlsIfAreValidImages(allLinks);
    return imageUrls;
  };

  const getSelectedWaypointImages = useCallback(async () => {
    if (!selectedWaypoint || selectedWaypoint.links.length < 1) return [];
    const images = await getUrlsIfAreValidImages(selectedWaypoint.links);
    return images;
  }, [selectedWaypoint]);

  const updateWaypointVisibility = useCallback(
    async (waypointId: number, visible: boolean) => {
      try {
        await updateWaypoint(waypointId, { visible });
      } catch (error) {
        showErrorNotification({
          title: t("generic.error_title"),
          message: t(
            "track_and_waypoint_visibility.waypoint_visibility_toggle_failure_text"
          ),
        });

        // Since we're using optimistic updates here we need to do a rollback
        setHiddenWaypointIds((hiddenWaypointIds) => {
          return visible
            ? [...hiddenWaypointIds, waypointId]
            : hiddenWaypointIds.filter(
                (hiddenWaypointId) => hiddenWaypointId !== waypointId
              );
        });
      }
    },
    [setHiddenWaypointIds, waypoints, updateWaypoint]
  );

  const toggleWaypointVisibility = useCallback(
    (trackId: number) => {
      setHiddenWaypointIds((hiddenWaypointIds) => {
        let visible: boolean;
        let updatedWaypointIds: number[];

        if (hiddenWaypointIds.length > 0) {
          const currentlyHiddenTrack = hiddenWaypointIds.includes(trackId);

          if (currentlyHiddenTrack) {
            visible = true;
            updatedWaypointIds = hiddenWaypointIds.filter(
              (hiddenWaypointId) => hiddenWaypointId !== trackId
            );
          } else {
            visible = false;
            updatedWaypointIds = [...hiddenWaypointIds, trackId];
          }
        } else {
          visible = false;
          updatedWaypointIds = [trackId];
        }

        updateWaypointVisibility(trackId, visible);
        return updatedWaypointIds;
      });
    },
    [setHiddenWaypointIds]
  );

  return {
    waypoints,
    hiddenWaypointIds,
    fetchWaypoints,
    selectedWaypoint,
    selectWaypoint,
    updateWaypoint,
    deleteWaypoints,
    createWaypoint,
    toggleWaypointVisibility,
    isAddingWaypoint,
    setIsAddingWaypoint,
    isFetching,
    waypointStatus,
    getImagesFromLinks,
    getSelectedWaypointImages,
  };
};

export const useUserWaypoints = () => useContext(UserWaypointsContext);

export const UserWaypointDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const userWaypointData = useProvideUserWaypoints();
  return (
    <UserWaypointsContext.Provider value={userWaypointData}>
      {children}
    </UserWaypointsContext.Provider>
  );
};
